// App.jsx

// Import polyfills at the top
import '../polyfills'; // Polyfills should be imported before anything else

import React, { useEffect, Suspense, lazy, useState, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import '../styles/styles.css';
import '../styles/missions.css';
import LoadingScreen from './LoadingScreen.jsx';
import { useUser, UserProvider } from '../contexts/UserContext';
import { GuestProvider } from '../contexts/GuestContext';
import { useLoading, LoadingProvider } from '../contexts/LoadingContext';
import { GameStatsProvider } from '../contexts/GameStatsContext';
import firebaseInstance from './firebaseSingleton.jsx';
import ErrorBoundary from './ErrorBoundary.jsx';
import { setAuthToken, removeAuthToken } from '../services/api';
import { getApps } from 'firebase/app';


// Lazy load components for better performance
const LandingPage = lazy(() => import('../components/LandingPage.jsx'));
const LoginPage = lazy(() => import('../components/LoginPage.jsx'));
const MissionsPage = lazy(() => import('../components/MissionsPage.jsx'));
const ProfilePage = lazy(() => import('../components/ProfilePage.jsx'));
const SocialMediaConnect = lazy(() =>
  import('../components/SocialMediaConnect.jsx')
);
const OAuthCallback = lazy(() => import('../components/OAuthCallback.jsx'));

// Footer pages
const TermsPage = lazy(() => import('../components/TermsPage.jsx'));
const PrivacyPolicyPage = lazy(() =>
  import('../components/PrivacyPolicyPage.jsx')
);
const ContactPage = lazy(() => import('../components/ContactPage.jsx'));
const AboutPage = lazy(() => import('../components/AboutPage.jsx'));
const CareersPage = lazy(() => import('../components/CareersPage.jsx'));

// Dashboard and main sections
const Dashboard = lazy(() => import('../components/Dashboard.jsx'));
const FeaturesPage = lazy(() => import('../components/FeaturesPage.jsx'));
const IntegrationsPage = lazy(() => import('../components/IntegrationsPage.jsx'));
const TestimonialsPage = lazy(() =>
  import('../components/TestimonialsPage.jsx')
);
const PricingPage = lazy(() => import('../components/PricingPage.jsx'));
const FAQPage = lazy(() => import('../components/FAQPage.jsx'));

// Simple Cookie Consent Component
const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    setShowBanner(!consent);
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true');
    localStorage.setItem('cookieConsentTimestamp', new Date().toISOString());
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-900 text-white p-4 z-50">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <p className="text-sm mr-8">
          This site uses cookies to provide you with a better user experience.
        </p>
        <button
          onClick={acceptCookies}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Accept
        </button>
      </div>
    </div>
  );
};

// Router configuration for v7 compatibility
const routerConfig = {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
  },
};

// Configuration object for API and services
const config = {
  apiUrl: import.meta.env.VITE_API_BASE_URL,
  authUrl: `${import.meta.env.VITE_API_BASE_URL}/auth`,
  githubAuthUrl: `https://github.com/login/oauth/authorize?client_id=${import.meta.env.VITE_GITHUB_CLIENT_ID}&scope=user`,
  twitterAuthUrl: `${import.meta.env.VITE_API_BASE_URL}/twitterCallback`,
  profileUrl: `${import.meta.env.VITE_API_BASE_URL}/profile`,
  missionsUrl: `${import.meta.env.VITE_API_BASE_URL}/missions`,
  socialMediaUrl: `${import.meta.env.VITE_API_BASE_URL}/socialMedia`,
  saveScoreUrl: `${import.meta.env.VITE_API_BASE_URL}/saveScore`,
  leaderboardUrl: `${import.meta.env.VITE_API_BASE_URL}/leaderboard`,
  referralUrl: `${import.meta.env.VITE_API_BASE_URL}/getReferralInfo`,
  cookieSettings: {
    sameSite: 'Lax',
    secure: true,
    path: '/',
    maxAge: 7200,
  },
};

// Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

const LOADING_TIMEOUT = 30000;

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user && !location.pathname.startsWith('/login')) {
      navigate('/login', { replace: true, state: { from: location } });
    }
  }, [user, navigate, location]);

  return user ? children : <LoadingScreen type="default" />;
};

// Firebase Initialization Component
const FirebaseInitializer = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const initializeFirebase = async () => {
      try {
        // Check if Firebase is already initialized
        if (getApps().length) {
          console.log('Firebase already initialized, using existing instance');
          setIsInitialized(true);
          return;
        }

        console.log('Initializing Firebase...');
        await firebaseInstance.initialize(firebaseConfig);

        if (isMounted) {
          setIsInitialized(true);
        }
      } catch (err) {
        console.error('Failed to initialize Firebase:', err);
        if (isMounted) {
          setError(err.message);
        }
      }
    };

    initializeFirebase();

    return () => {
      isMounted = false;
    };
  }, []);

  if (error) {
    return (
      <div className="error-container bg-red-100 p-4 rounded">
        <h2 className="text-red-800 font-bold">Error initializing app</h2>
        <p className="text-red-600">{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Retry
        </button>
      </div>
    );
  }

  if (!isInitialized) {
    return <LoadingScreen type="default" />;
  }

  return children;
};

// App Content Component
const AppContent = () => {
  const { user, error: userError, logout } = useUser();
  const { loading, setLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();
  const [appError, setAppError] = useState(null);
  const [initializationComplete, setInitializationComplete] = useState(false);

  // Simplified initialization check
  const checkInitialization = useCallback(async () => {
    try {
      setLoading(true);
      // Just check if Firebase is initialized
      if (getApps().length && firebaseInstance.isInitialized()) {
        setInitializationComplete(true);
      }
    } catch (err) {
      console.error('Error checking initialization:', err);
      setAppError(err.message);
    } finally {
      setLoading(false);
    }
  }, [setLoading]);

  useEffect(() => {
    if (!initializationComplete) {
      checkInitialization();
    }

    const loadingTimeout = setTimeout(() => {
      if (!initializationComplete) {
        setLoading(false);
        setAppError('App initialization timed out. Please refresh the page.');
      }
    }, LOADING_TIMEOUT);

    return () => clearTimeout(loadingTimeout);
  }, [checkInitialization, initializationComplete, setLoading]);

  useEffect(() => {
    const checkUserAndNavigate = () => {
      const publicRoutes = ['/', '/login', '/auth/callback'];
      if (
        !loading &&
        initializationComplete &&
        !publicRoutes.includes(location.pathname) &&
        !user
      ) {
        navigate('/login', { replace: true });
      }
    };
    checkUserAndNavigate();
  }, [loading, initializationComplete, navigate, location.pathname, user]);

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      removeAuthToken();
      navigate('/login');
    } catch (error) {
      setAppError('Failed to log out. Please try again.');
    }
  }, [logout, navigate]);

  if (loading || !initializationComplete) {
    return <LoadingScreen type="default" />;
  }

  if (appError || userError) {
    return (
      <div className="error-container">
        <h2>An error occurred</h2>
        <p>{appError || userError}</p>
        <button onClick={() => window.location.reload()} className="btn-refresh">
          Refresh Page
        </button>
      </div>
    );
  }

  return (
    <div className="app">
      <CookieConsent />
      <main id="main-content" role="main" className="flex-grow">
        <Suspense fallback={<LoadingScreen type="default" />}>
          <Routes {...routerConfig}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage config={config} />} />
            <Route path="/auth/callback" element={<OAuthCallback />} />
            <Route
              path="/missions"
              element={
                <ProtectedRoute>
                  <MissionsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/social/connect"
              element={
                <ProtectedRoute>
                  <SocialMediaConnect />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/features" element={<FeaturesPage />} />
            <Route path="/integrations" element={<IntegrationsPage />} />
            <Route path="/testimonials" element={<TestimonialsPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </main>
    </div>
  );
};

const NotFoundPage = () => (
  <div className="not-found-container">
    <h1>404 - Page Not Found</h1>
    <p>Oops! The page you are looking for does not exist.</p>
    <button
      onClick={() => (window.location.href = '/')}
      className="mt-4 bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded"
    >
      Go to Home
    </button>
  </div>
);

const App = () => (
  <Router>
    <ErrorBoundary
      fallback={({ error }) => (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
            <h2 className="text-2xl font-bold text-red-600 mb-4">
              Something went wrong
            </h2>
            <p className="text-gray-600 mb-4">{error.message}</p>
            <button
              onClick={() => window.location.reload()}
              className="w-full py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              Reload Application
            </button>
          </div>
        </div>
      )}
    >
      <FirebaseInitializer>
        <GuestProvider>
          <LoadingProvider>
            <UserProvider>
              <GameStatsProvider>
                <AppContent />
              </GameStatsProvider>
            </UserProvider>
          </LoadingProvider>
        </GuestProvider>
      </FirebaseInitializer>
    </ErrorBoundary>
  </Router>
);

// Initialize the application
const initApp = () => {
  try {
    const rootElement = document.getElementById('root');
    if (!rootElement) throw new Error('Root element not found');

    const root = createRoot(rootElement);

    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } catch (error) {
    console.error('Failed to initialize app:', error);
    document.body.innerHTML = `
      <div class="min-h-screen flex items-center justify-center bg-gray-100">
        <div class="bg-white p-8 rounded-lg shadow-xl max-w-md w-full text-center">
          <h1 class="text-2xl font-bold text-red-600 mb-4">Failed to load application</h1>
          <p class="text-gray-600 mb-4">${error.message}</p>
          <p class="text-sm text-gray-500 mb-4">Please try refreshing the page or contact support if the issue persists.</p>
          <button 
            onclick="window.location.reload()"
            class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Refresh Page
          </button>
        </div>
      </div>
    `;
  }
};

document.addEventListener('DOMContentLoaded', initApp);

export default App;
